:root {
  --color-one: #42a5d1; /** dropdown colour **/
  --color-two: #6BC9F2; /** dropdown button hover **/
  --color-three: #007096; /** font colour **/
  --color-four: #1ab7ea; /** button colour **/
  --color-white: #ffffff;
  --header-height: 62px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre { 
  white-space: pre-wrap; 
  word-break: break-word;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;       
  background-color:#615b5b;
  height: 1px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
  color: #7d7e80;
  font-size: 1.2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #7d7e80;
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.6em;
}
h2, .h2 {
  font-size: 1.5em;
}
h3, .h3 {
  font-size: 1.17em;
}
h4, .h4 {
  font-size: 1em;
}
h5, .h5 {
  font-size: 0.83em;
}
h6, .h6 {
  font-size: .67em;
}

p, .p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 0;
  font-size: inherit;
}

.font-size-1rem {
  font-size: 1rem;
}

span {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
}

button, .button {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
  border: none;
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.max-width {
  width: 100%;
}
.max-height {
  height: 100%;
}

.page {
  min-height: calc(100vh - var(--header-height));
  padding-top: 62px;
}
.item {
  height: 100%;
  width: 100%;
}

/**
  Display
**/
.display-block {
  display: block !important;
}
.display-none {
  display: none !important;
}
.display-inline-block {
  display: inline-block;
}

/**
  Header
**/
.header, header {
  height: var(--header-height);
}

/**
  Footer
**/
.footer, footer {
  margin-top: auto;
  color: grey;
}


/**
  Color
**/
.color-one {
  color: var(--color-one);
}
.background-one {
  background-color: var(--color-one);
}
.color-two {
  color: var(--color-two);
}
.background-two {
  background-color: var(--color-two);
}
.color-three {
  color: var(--color-three);
}
.background-three {
  background-color: var(--color-three);
}
.color-four {
  color: var(--color-four);
}
.background-four {
  background-color: var(--color-four);
}
.color-white {
  color: var(--color-white);
}
.background-white {
  background-color: var(--color-white);
}
.background-none {
  background: none;
}
.background-grey {
  background-color: #f1f1f1;
}
.link-blue {
  color: #007bff;
}

/**
  Flex
**/
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex {
  flex: 1 0 0;
}

.border-none {
  border: none;
}
.border {
  border: 1px solid black;
}
.border-black {
  border: 1px solid black;
}
.border-grey {
  border: 1px solid grey;
}
.border-light-grey {
  border: 1px solid rgba(0,0,0,.125);
}

/**
  Margin
**/
.margin-0 {
  margin: 0px;
}
.margin-5 {
  margin: 5px;
}
.margin-10 {
  margin: 10px;
}
.margin-20 {
  margin: 20px;
}
.margin-30 {
  margin: 30px;
}
.margin-40 {
  margin: 40px;
}
.margin-50 {
  margin: 50px;
}
.margin-60 {
  margin: 60px;
}
.margin-70 {
  margin: 70px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-right-auto {
  margin-right: auto;
}
.margin-auto {
  margin: auto;
}

/**
  Padding
**/
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.padding-30 {
  padding: 30px;
}
.padding-40 {
  padding: 40px;
}
.padding-50 {
  padding: 50px;
}
.padding-60 {
  padding: 60px;
}
.padding-70 {
  padding: 70px;
}
.padding-1rem {
  padding: 1rem;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-60 {
  padding-top: 60px;
}
.padding-top-70 {
  padding-top: 70px;
}
.padding-bottom-5 {
  padding-bottom: 5px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-60 {
  padding-bottom: 60px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}
.padding-bottom-6rem {
  padding-bottom: 6rem;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-30 {
  padding-left: 30px;
}
.padding-left-40 {
  padding-left: 40px;
}
.padding-left-50 {
  padding-left: 50px;
}
.padding-left-60 {
  padding-left: 60px;
}
.padding-left-70 {
  padding-left: 70px;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-30 {
  padding-right: 30px;
}
.padding-right-40 {
  padding-right: 40px;
}
.padding-right-50 {
  padding-right: 50px;
}
.padding-right-60 {
  padding-right: 60px;
}
.padding-right-70 {
  padding-right: 70px;
}

/**
  Border Radidus
**/
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-15 {
  border-radius: 15px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-100 {
  border-radius: 100%;
}

/**
  Align-items
**/
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: start;
}

/**
  Justify-content
**/
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

/**
  Text-align
**/
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

/**
  contact-owner-modal

**/
.contact-owner-modal {
  position: fixed;
  z-index: 1337;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-owner-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  min-width: 575px;
  margin-top: -5%;
}

/**
  Cursors
**/
.cursor-pointer {
  cursor: pointer;
}

/**
  Background Color Hover
**/
.background-color-one-hover:hover {
  background-color: var(--color-one);
  transition: background-color 0.5s ease;
}
.background-color-two-hover:hover {
  background-color: var(--color-two);
  transition: background-color 0.5s ease;
}
.background-color-three-hover:hover {
  background-color: var(--color-three);
  transition: background-color 0.5s ease;
}
.background-color-four-hover:hover {
  background-color: var(--color-four);
  transition: background-color 0.5s ease;
}
.background-color-light-grey:hover {
  background-color: #f7f7f7;
}

/**
  Color Hover
**/
.color-one-hover:hover {
  color: var(--color-one);
  transition: background-color 0.5s ease;
}
.color-two-hover:hover {
  color: var(--color-two);
  transition: background-color 0.5s ease;
}
.color-three-hover:hover {
  color: var(--color-three);
  transition: background-color 0.5s ease;
}
.color-four-hover:hover {
  color: var(--color-four);
  transition: background-color 0.5s ease;
}

/**
  Text Decoration
**/
.text-decoration-none {
  text-decoration: none;
}


.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.05);
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  width: 40%;
  margin-left: 30%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 2;
}


/** Loader **/
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: var(--color-four);
  background: -moz-linear-gradient(left, var(--color-four) 10%, rgba(0,128,255, 0) 42%);
  background: -webkit-linear-gradient(left, var(--color-four) 10%, rgba(0,128,255, 0) 42%);
  background: -o-linear-gradient(left, var(--color-four) 10%, rgba(0,128,255, 0) 42%);
  background: -ms-linear-gradient(left, var(--color-four) 10%, rgba(0,128,255, 0) 42%);
  background: linear-gradient(to right, var(--color-four) 10%, rgba(0,128,255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  overflow: hidden;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: var(--color-four);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
 Content Wrap 
**/
.content-wrap {
  padding-top: 30px;
  padding-bottom: 6rem;
}

/* For privacy policy page */
.privacy-h1{
  font-size: 2em;
}

/****** Lost and Found Pets Page ******/
.lost-pets-small-text,
.found-pets-small-text {
  padding-top: 1.5%;
  padding-left: 15%;
  padding-right: 15%;
}

.lost-pets-container,
.found-pets-container {
  overflow-x: hidden;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
}

.card img {
  width: 100%;
  height: 16em;
}

.card-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5%;
}

.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-pet-header {
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.card-contact-owner-button {
  cursor: pointer;
  width: 127.78px;
  height: 30px;
  font-size: 17px;
  justify-content: center;
  border: 0.25px;
  border-color: rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 2.5%;
  font-size: 1rem;
  font-weight: 1200;
}

.contact-owner-title {
  margin-top: 0;
}

.contact-owner-button-container {
  padding-bottom: 5%;
}

.lost-pets-search-button, .lost-pets-search-bar,
.found-pets-search-button, .found-pets-search-bar {
  font-size: 1rem;
}

.lost-pets-search-button, .found-pets-search-button,
 .card-contact-owner-button, .submit-contact-form-button {
  background-color: #1ab7ea;
  color: white;
}

.lost-pets-search-button:hover, .found-pets-search-button:hover,
 .card-contact-owner-button:hover, .submit-contact-form-button:hover{
  background-color: #42a5d1;
}

.lost-pets-search-button:hover, 
.found-pets-search-button:hover, 
.card-contact-owner-button:hover, 
.submit-contact-form-button:hover,
.close-button:hover
{
  cursor: pointer;
}


.lost-pets-search-button,
.found-pets-search-button{
  padding-bottom: 0.25%;
}

.form-container {
  width: 30rem;
  text-align: center;
  padding: 10px;
  border: 3px solid #f1f1f1;
  background: white;
  color: #808080;
  margin-top: 2.5%;

}

.contact-owner-popup {
  position: fixed; 
  top: 50%;
  left: 50%;
  z-index: 9;

}

.contact-owner-modal {
  position: fixed; 
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.8);
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=tel], .form-container input[type=email] {
  width: 95%;
  padding: 15px;
  margin: 0 0 22px 0;
  border: none;
  background: #f1f1f1;
  box-sizing: border-box;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/close button */
.form-container button {
  border: none;
  color: white;
  width: 100%;
  margin-bottom:5px;
  opacity: 0.8;
  font-size: 1.25rem;
}

/* Add a red background color to the cancel button */
.form-container .close-button  {
  background-color: red;
}

.close-button:hover {
  background-color: #db0909;
}

@media screen and (max-height: 820px) and (min-height: 685px) {
  .form-container {
    -webkit-transform:scale(0.85);
    -moz-transform:scale(0.85);
    -ms-transform:scale(0.85);
    transform:scale(0.85);
    width: 75%;
    margin-top: 5%;
  }

  .form-container input[type=text], 
  .form-container input[type=tel], 
  .form-container input[type=email] {
    font-size: 1.5rem;
  }

  
  .form-container h1{
    font-size: 5vmin;
  }

  .form-container h2 {
    font-size: 4.5vmin;
  }

  .form-container button {
    font-size: 2.5vmin;
  }

  .form-container label{
    font-size: 3vmin;
  }


}

@media screen and (max-height: 680px) and (max-width: 1440px) {
  .form-container {
    -webkit-transform:scale(0.85);
    -moz-transform:scale(0.85);
    -ms-transform:scale(0.85);
    transform:scale(0.8);
    width: 65%;
    margin-bottom: 10%;
  }
  .form-container h1 {
    font-size: 5vmin;
}

  .form-container h2 {
    font-size: 4.5vmin;
  }

  .form-container button {
    font-size: 4vmin;
  }

  .form-container label{
    font-size: 3vmin;
  }

  .form-container {
    margin-top: 16%;
  }
}

@media screen and (max-height: 684px) and (min-height: 638px) {
  .form-container {
    -webkit-transform:scale(0.85);
    -moz-transform:scale(0.85);
    -ms-transform:scale(0.85);
    transform:scale(0.85);
    width: 65%;
    margin-bottom: 12.5%;
  }
  .form-container h1 {
    font-size: 5vmin;
}

  .form-container h2 {
    font-size: 4.5vmin;
  }

  .form-container button {
    font-size: 4vmin;
  }

  .form-container label{
    font-size: 3vmin;
  }

  .form-container {
    margin-top: 16%;
  }
}

@media screen and (max-width: 500px) {
  .form-container {
    margin-top: 30%;
    width: 100%;
  }

  .form-container button {
    font-size: 1rem;
  }

  .form-container p,
  .form-container label {
    font-size: 1rem;
  }

  .form-container input[type=text], 
  .form-container input[type=tel], 
  .form-container input[type=email] {
    padding: 5px;
  }
  
}

@media screen and (max-height: 612px){
  .form-container {
    -webkit-transform:scale(0.35);
    -moz-transform:scale(0.35);
    -ms-transform:scale(0.35);
    transform:scale(0.55);
    width: 100%;
  }

  .form-container h1 {
    font-size: 4vmax;
}

  .form-container h2 {
    font-size: 3.5vmax;
  }

  .form-container button {
    font-size: 2.5vmax;
  }

  .form-container label{
    font-size: 2.75vmax;
  }

  .form-container p{
    font-size: 2vmax;
  }

  .form-container input[type=text], 
  .form-container input[type=tel], 
  .form-container input[type=email] {
    font-size: 4vmax;
    margin-bottom: 2px;
  }
}

@media (max-width: 575px) {
  .card {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .contact-owner-popup {
    margin-left: -40%;
    margin-top: -40%;
  }
}

@media (max-height: 568px) and (max-width: 320px) {
  .form-container {
    -webkit-transform:scale(0.95);
    -moz-transform:scale(0.95);
    -ms-transform:scale(0.95);
    transform:scale(0.7);
    margin-top: 19.25%;
    width: 100%;
  }

  .form-container input[type=text], 
  .form-container input[type=tel], 
  .form-container input[type=email] {
    padding: 5px;
  }
}

@media (max-height: 630px) and (max-width: 765px) {
  .form-container {
    -webkit-transform:scale(0.95);
    -moz-transform:scale(0.95);
    -ms-transform:scale(0.95);
    transform:scale(0.9);
    margin-top: 19.25%;
    width: 100%;
  }
}

@media (max-height: 375px) and (max-width: 812px) {
  .form-container {
    -webkit-transform:scale(0.5);
    -moz-transform:scale(0.5);
    -ms-transform:scale(0.5);
    transform:scale(0.47);
    margin-top: 20%;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .card {
      -ms-flex: 0 0 49%;
      flex: 0 0 49%;
      max-width: 49%;
  }
}

@media (min-width: 992px) {
  .card {
      -ms-flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
  }
  .contact-owner-popup {
    margin-left: -20%;
    margin-top: -25%;
  }
}

@media (min-width: 1200px) {
  .card{
      -ms-flex: 0 0 24.75%;
      flex: 0 0 24.75%;
      max-width: 24.75%;
  }
  .contact-owner-popup {
    margin-left: -12.5%;
    margin-top: -17.5%
  }
}


@media screen and (max-height: 499px) and (min-height: 400px) {
  .form-container {
    -webkit-transform:scale(0.5);
    -moz-transform:scale(0.5);
    -ms-transform:scale(0.5);
    transform:scale(0.5);
    margin-top: 17.5%;
  }

  .form-container p {
    font-size: 16px;
  }
}

/***********************************************/




/* for pet profile */
.pet_profile {
  background-color: #ededeb;
}

.pet_profile > div {
  display: inline-block;
  text-align: left;
  padding: 10px;
}

.pet_list {
  list-style-type:none;
}

#pet_photo {
  width: 300px;
}

.pet_th {
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
}

#status_div {
  padding: 25px 10px;
}

#care_info {
  display: none;
}

.profile_buttons {
  margin: 10px;
  width: 380px;
  max-width: 90%;
  
}

#update_history_div {
  display: none;
}

#update_history_div div, #rescue_timeline_body div {
  display: inline-block;
  text-align: left;
  width: 380px;
  max-width: 90%;
  padding: 10px;
  border: 1px solid #d4d2d2;
}

#rescue_timeline_body {
  display: none;
}

#add_rescue_timeline {
  box-sizing: border-box;
  text-align: left;
  width: 320px;
  max-width: 100%;
  border: 1px solid gray;
  padding: 10px;
  display: none;
  position: absolute; 
  bottom: 10%;
  left: 30%;
  z-index: 9;
  background-color: #fff;
}

.PetProfileContainer {
  position: relative;
  min-height: 100vh;
  text-align: center;
  margin-top: 30px;
}

.centered_button {
  text-align: center;
}

#content_wrap {
  padding-top: 30px;
  padding-bottom: 6rem; /* height of the footer */
}

.adjusted-div {
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}

.change_location_button{
  margin: 20px;
}

.StatusHistoryField{
  font-size: 1rem;
  display: block;
  text-align: left;
  margin: 0 auto;
  width: 380px;
  max-width: 90%;
  padding: 10px;
  border: 1px solid #1ab7ea;
}

/******************************/

/*Pet Status contact-owner-modal*/
.ChangeStatus {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 8px;
  left: 15%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.CommentForm {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 350px;
  text-align: center;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 8px;
  left: 15%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.ChangeStatusFormLabel {
  font-size: 1rem;
  width: 100%;
}

.ChangeStatusFormLabel > input {
  width: 100%;
  padding: 6px 20px;
  margin: 4px 0;
  box-sizing: border-box;
}

@media (min-width: 700px) {
  .ChangeStatus {
      width: 400px;
      top: 10%;
      left: calc(50% - 200px);
  }
}
@media (max-width: 700px) {
.ChangeStatusFormLabel > input {
  width: 100%;
  padding: 3px 10px;
  margin: 0px 0;
  box-sizing: border-box;
}
}

/*Pet Status Modal*/

/****Edit pet profile***/

.EditProfileFormLabel {
  width: 100%;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}



.EditPetProfileWrapper {
  width: 1024px;
  max-width: 95%;
  margin: 10px auto;
}

.floating-panel {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  border: 1px solid #999;
  line-height: 30px;
  padding-left: 10px;
}

.EditProfileForm{
  color: grey;
  font-family: 'Open sans', sans-serif;
}

/*** 
Register Lost Pet
***/

#page_container .padding-top-30 {
  font-size: 1.2rem;
  overflow-x: hidden;
}

form.padding-1rem input, label, p{
  font-size: 1.2rem;
}

form.padding-1rem p.disclaimer {
  font-size: 90%;
}

.wrapper {
  width: 1024px;
  max-width: 95%;
  margin: 10px auto;
}

.form-buttons {
  color:#fff;
  background-color:#1ab7ea;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.form-buttons:focus, .form-buttons.focus {
  color:#fff;
  background-color:#1295bf;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.form-buttons:hover, .form-buttons.hover { 
  color:#fff;
  background-color:#1295bf;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.form-buttons:active, .form-buttons.active {
  color:#fff;
  background-color:#1295bf;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.form-buttons:active:hover, .form-buttons.active:hover, .form-buttons:active:focus, .form-buttons.active:focus, .form-buttons:active.focus, .form-buttons.active.focus {
  color:#fff;
  background-color:#0f7b9f;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.form-buttons:active, .form-buttons.active {
  background-image:none
}

.form-buttons.disabled:hover, .form-buttons[disabled]:hover, .form-buttons.disabled:focus, .form-buttons[disabled]:focus, .form-buttons.disabled.focus, .form-buttons[disabled].focus { 
  background-color:#1ab7ea;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.submit_button {
  width: 60%;
  margin: 1%;
  color: #fff;
  background-color:#1ab7ea;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.submit_button:disabled {
  background-color: lightgrey;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.submit_button:disabled:hover {
  background-color: lightgrey;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.select_photo_button {
  text-align: center;
  font-size: 12pt;
  padding: 3px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #f0f0f0;
  width: 130px;
  cursor: pointer;
  border: 1px solid #dedede;
  border-radius: 3px;
}

.select_photo_button:hover {
  background-color: #dedede;
}

#imageFileName {
  font-size: 12pt;
  color: gray;
}

#removePhotoButton {
  font-size: 12pt;
  color: gray;
  display: none;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 3px 8px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-top: 0.5rem;
}

.primary_button {
    border-radius: 8px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 800px;
    padding: 5%;
    text-align: center;
    font-size: 1.3em;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.adjusted_box {
  width: 800px;
  max-width: 95%;
  margin: 30px auto;
  padding: 10px;
  text-align: left;
  border: 1px solid #d4d2d2;
}

@media only screen and (max-width: 600px) {
  .userTitle {
    visibility: hidden;
  }

  .index-forms {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    width: 90%;
    margin-bottom: 20px;
  }

  .map-form-inputs {
    display: flex;
    flex-direction: column;
  }
  .changeStatusModal {
    position: fixed; 
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .erm-second-column {
    max-width: 200px;
  }

  .dropdown-content {
    width: 90%;
    margin-left: 5%;
  }
}

@media only screen and (min-width: 601px) {
  .index-forms {
    display: flex;
    flex-direction: row;
    width: 30%;
  }

  .phoneMessage {
    display: none;
  }

  .map-form-inputs {
    display: flex;
    flex-direction: row;
  }

  .changeStatusModal {
    position: fixed;
    z-index: 500;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    box-sizing: border-box;
  }
}

.overflowModal {
  overflow-y: auto;
  max-height: 70vh;
}
